.six_protection_small{
  width: 1180px;
  margin: 0px auto 10px;
  hr{
    height: 1px;
    border: 0;
    background: #DDDDDD;
  }
  >img{
    width: 100%;
  }
  .book{
    width: 1200px !important;
    margin: 0 auto 10px;
  }
  .six_protection_body{
    display: flex;
    align-items: center;
    height: 109px;
    justify-content: space-between;
    .six_protection_logo{
      height: 28px;
    }
    .six_protection_content{
      // margin-left: 43px;
      color: #DDDDDD;
      display: flex;
      align-items: center;
      a{
        color: #999999;
      }
      img{
        width: 16px;
        margin-right: 3px;
      }
    }
  }
}
.book{
  width: 1200px !important;
  margin: 0 auto 10px;
}
